export const useBackend = () => {
  const subscribe = async (email) => {
    try {
      console.log('API URL:', process.env.REACT_APP_LANDING_API_URL);
      const response = await fetch(`${process.env.REACT_APP_LANDING_API_URL}/api/subscribe`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email })
      });
      
      console.log('Response status:', response.status);
      const data = await response.json();
      console.log('Response data:', data);

      if (response.status === 409) {
        throw new Error('DUPLICATE_EMAIL');
      }
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      if (!data.success) {
        throw new Error(`Subscription failed: ${data.error || 'Unknown error'}`);
      }
      return data;
    } catch (error) {
      console.error('Full error details:', {
        message: error.message,
        status: error.status,
        stack: error.stack
      });
      throw error; // Throw original error with details
    }
  };
  return { subscribe };
};