import { useState } from 'react';
import { useBackend } from './hooks/useBackend';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';
import RedditIcon from '@mui/icons-material/Reddit';
import './App.css';

function App() {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');
  const { subscribe } = useBackend();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await subscribe(email);
      setSubmitted(true);
      setEmail('');
      setError('');
    } catch (error) {
      if (error.message === 'DUPLICATE_EMAIL') {
        setError("You're already subscribed! We'll keep you updated.");
      } else {
        setError('Subscription failed. Please try again.');
      }
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="social-links">
          <a href="https://www.linkedin.com/company/revisus" target="_blank" rel="noopener noreferrer">
            <LinkedInIcon />
          </a>
          <a href="https://x.com/revisus" target="_blank" rel="noopener noreferrer">
            <XIcon />
          </a>
          <a href="https://www.reddit.com/user/hello_revisus/" target="_blank" rel="noopener noreferrer">
            <RedditIcon />
          </a>
        </div>
        <h1 className='company-name'>revisus</h1>
        <p>Leading Automotive Data Analysis Beyond Tomorrow.</p>
        <div className="coming-soon">
          <p>Something exciting is coming soon!</p>
        </div>
        <form onSubmit={handleSubmit} className="subscribe-form">
          {!submitted ? (
            <>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                required
              />
              <button type="submit">Subscribe for Updates</button>
              {error && <p className="error-message">{error}</p>}
            </>
          ) : (
            <p className="success-message">Thanks for subscribing!</p>
          )}
        </form>
      </header>
    </div>
  );
}

export default App;
